import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { VocabularyCard, AudioButton, Box } from 'components';
import { useBooksResource } from 'utils';
import { UiExampleCard } from './ExampleCard.style';

/**
 * 單字例句 卡牌
 */

export const ExampleCard = ({ data, isActive }) => {
	const { KK, example, exampleTranslation, word, partOfSpeech, wordTranslation, exampleSound } = data;
	const { getAudioSource } = useBooksResource();
	const audioSource = getAudioSource(exampleSound);

	return (
		<UiExampleCard>
			<VocabularyCard
				className="vocabularyCard"
				KK={KK}
				word={word}
				partOfSpeech={partOfSpeech}
				translation={wordTranslation}
			/>
			<div className="exampleContainer">
				<div className="example">
					{Array.isArray(example) ? (
						<>
							{example.map((item) => (
								<div key={item} dangerouslySetInnerHTML={{ __html: item }} />
							))}
						</>
					) : (
						example
					)}
				</div>
				<div className="translation">
					{Array.isArray(exampleTranslation) ? (
						<>
							{exampleTranslation.map((item) => (
								<div key={item} dangerouslySetInnerHTML={{ __html: item }} />
							))}
						</>
					) : (
						exampleTranslation
					)}
				</div>
			</div>
			<Box className="audioButton">{isActive && <AudioButton src={audioSource} variant="inverse" size="small" />}</Box>
		</UiExampleCard>
	);
};

ExampleCard.propTypes = {
	data: PropTypes.object,
	isActive: PropTypes.bool,
};
