import styled from 'styled-components';

export const UiPopup = styled.div`
	position: relative;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10;
`;

export const UiPopupBox = styled.div`
	position: absolute;
	padding: 18px 16px 32px;
	width: 100%;
	background-color: #fff;
	border-radius: 10px 10px 0 0;

	> .head {
		position: relative;

		> .title {
			font-weight: 600;
			text-align: center;
		}

		> .closeBtn {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

	> .content {
		margin-top: 16px;
		color: ${({ theme }) => theme.colors.paragraphs};
	}

	> .actions {
		margin-top: 28px;
	}
`;
