import styled from 'styled-components';

export const UiControlButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	width: auto;
	height: 50px;
	padding: 0 10px;
	appearance: none;
	background-color: transparent;
	color: ${({ active }) => (active ? '#cc0000' : '#fff')};
	font-weight: bold;
	border: 0px;
	outline: none;
	cursor: pointer;
	font-size: 30px;
	font-family: -apple-system, BlinkMacSystemFont, Microsoft JhengHei, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, Arial, Helvetica, sans-serif;
	i {
		color: #fff;
	}
`;
