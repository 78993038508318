import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ControlButton from 'components/Player/ControlButton';
import ProcessBar from 'components/Player/ProcessBar';
import VolumeBar from 'components/Player/VolumeBar';
import Subtitles from 'components/Player/Subtitles';
import SubtitleControl from 'components/Player/SubtitleControl';
import { useVideo } from 'utils/hooks/mediaElement';
import { VideoIcon } from 'components';

import { UiVideoPlayer } from './VideoPlayer.style';

const controller = new AbortController();

/**
 * 在這邊描述這個組件
 */

export const VideoPlayer = ({ className, src, poster, subtitles, onCloseHandler }) => {
	const {
		element: video,
		state,
		controls,
		activeCue,
	} = useVideo(
		<video src={src} playsInline crossOrigin="anonymous" poster={poster}>
			{subtitles?.map((subtitle) => (
				<track
					key={subtitle.language}
					kind="subtitles"
					label={subtitle.language}
					srcLang={subtitle.language}
					src={subtitle.src}
				/>
			))}
		</video>,
	);

	useEffect(() => {
		if (state.isLoaded) {
			controls.play();
		}
	}, [src, state.isLoaded]);

	const onVideoCloseHandler = () => {
		controls.pause();
		// controls.seek(0);
		onCloseHandler();
		controller.abort();
	};

	return (
		<UiVideoPlayer>
			<div className="video">
				{video}
				{state.language.length > 0 && <Subtitles activeCue={activeCue} language={state.language} />}
				<div className="controlBar">
					<ProcessBar
						className="processBar"
						currentTime={state.time}
						endTime={state.duration}
						controls={controls}
						draggable
					/>
					<div className="left">
						<ControlButton active={true} onClick={onVideoCloseHandler}>
							<VideoIcon name="timesCircle"></VideoIcon>
						</ControlButton>
						<ControlButton onClick={state.isPlaying ? controls.pause : controls.play}>
							{state.isPlaying ? <VideoIcon type="text" name="pause" /> : <VideoIcon type="text" name="play" />}
						</ControlButton>
						<VolumeBar state={state} controls={controls} onChange={(val) => controls.volume(val)} />
					</div>
					<div className="right">
						<SubtitleControl value={state.language} subtitles={subtitles} onChange={controls.setSubtitlesLanguage} />
					</div>
				</div>
			</div>
		</UiVideoPlayer>
	);
};

VideoPlayer.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
	poster: PropTypes.string,
	subtitles: PropTypes.array,
	onCloseHandler: PropTypes.func,
};
