import React from 'react';
import { TextBookWrap } from 'components';

/**
 * 課文對話頁
 */

export const DialoguePage = () => {
	return <TextBookWrap textBookType={'dialogue'} />;
};

DialoguePage.propTypes = {};
