const theme = {
	colors: {
		primary: '#EC7963',
		supporting: '#4C99A5',
		tertiary: '#727279',
		headlines: '#232735',
		paragraphs: '#505565',
		secondary: '#8B90A0',
		inverse: '#ffffff',
		white: '#ffffff',
	},
	breakpoints: ['767.98px', '991.98px', '1199.98px'],
	space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
};

export default theme;
