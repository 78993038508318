import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import { BooksMachine } from './state';

const StateContext = createContext({});
const DispatchContext = createContext(() => {});

const BooksProvider = ({ children }) => {
	const [state, dispatch] = useMachine(BooksMachine);

	return (
		<StateContext.Provider value={state}>
			<DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
		</StateContext.Provider>
	);
};

BooksProvider.propTypes = {
	children: PropTypes.node,
};

const useBooksState = () => {
	const state = useContext(StateContext);
	return state;
};

const useBooksDispatch = () => {
	const dispatch = useContext(DispatchContext);
	return dispatch;
};

export { BooksProvider, useBooksState, useBooksDispatch };
