import styled from 'styled-components';

export const UiHomePage = styled.div`
	display: block;

	.pageTitle {
		margin-bottom: 4px;
		padding: 12px 16px;
		font-size: 24px;
		color: ${({ theme }) => theme.colors.primary};
	}

	.coverWrap {
		margin: 0 auto;
		padding: 13px;
		width: 50%;
		transition: 0.3s;

		> img {
			overflow: hidden;
			width: 100%;
			border-radius: 10px;
			box-shadow: 3px 3px 3px rgba(196, 196, 196, 0.6);
			transition: 0.3s;
		}
	}
`;

export const UiBookInfo = styled.div`
	padding: 28px;

	> .head {
		font-size: 18px;
		color: ${({ theme }) => theme.colors.headlines};

		&::after {
			display: block;
			margin-top: 8px;
			width: 40px;
			height: 2px;
			background-color: ${({ theme }) => theme.colors.primary};
			content: '';
		}
	}
`;

export const UiTab = styled.div`
	border-bottom: 1px solid #f2f4f8;

	.swiper-slide {
		width: 100px;
		cursor: pointer;

		&.tab {
			text-align: center;
			color: #d3d4d8;

			&.is-active {
				color: ${({ theme }) => theme.colors.headlines};
			}

			> .item {
				position: relative;
				display: inline-block;
				padding: 8px 0;

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					display: block;
					width: 0;
					height: 2px;
					background-color: ${({ theme }) => theme.colors.primary};
					border-radius: 2px 2px 0 0;
					transition: 0.3s;
					content: '';
				}

				&.is-active {
					&::after {
						width: 100%;
					}
				}
			}
		}
	}
`;
