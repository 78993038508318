import styled from 'styled-components';

export const UiTextBookWrap = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.primary};

	> .pageTitle {
		padding: 15px 0;
		font-size: 20px;
		text-align: center;
		color: ${({ theme }) => theme.colors.white};
		line-height: 1.5em;
	}
`;

export const UiLessonTitle = styled.div`
	display: flex;
	padding: 0 24px;
	width: 100%;
	color: ${({ theme }) => theme.colors.white};
`;
export const UiLessonText = styled.div`
	overflow: hidden;
	width: ${({ titleover }) => (titleover ? 'calc(100% - 32px)' : '100%')};
	font-size: 20px;
	font-weight: 600;
	text-align: center;
	text-overflow: ${({ titleover }) => (titleover ? 'ellipsis' : 'clip')};
	white-space: ${({ titleover }) => (titleover ? 'nowrap' : 'normal')};
	line-height: 1.5em;
`;

export const UiIconBtn = styled.div`
	flex-basis: 1 0 32px;
`;

export const UiLessonContent = styled.div`
	overflow: scroll;
	box-sizing: border-box;
	margin: 24px 24px 0;
	height: calc(100% - 80px - 24px - 48px - 35px - 24px);
	background-color: #fff;
	border-radius: 10px;

	.highLight {
		font-weight: bold;
		text-decoration: underline;
		color: ${({ theme }) => theme.colors.supporting};
	}
`;
export const UiSentenceGroup = styled.div`
	padding: 12px 20px;
	background-color: ${({ current }) => (current ? '#f2f4f8' : 'transparent')};
`;

export const UiSentenceAction = styled.div`
	display: ${({ current }) => (current ? 'flex' : 'none')};
	justify-content: flex-end;
`;

export const UiSentence = styled.div`
	line-height: 32px;
	font-size: 18px;
	font-weight: ${({ current }) => (current ? 'bold' : 'normal')};
`;
