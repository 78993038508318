import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import { LessonMachine, ActionType, States } from './state';

const StateContext = createContext({});
const DispatchContext = createContext(() => {});

const LessonProvider = ({ children }) => {
	const [state, dispatch] = useMachine(LessonMachine);
	return (
		<StateContext.Provider value={state}>
			<DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
		</StateContext.Provider>
	);
};

LessonProvider.propTypes = {
	children: PropTypes.node,
};

const useLessonState = () => {
	const state = useContext(StateContext);
	return state;
};

const useLessonDispatch = () => {
	const dispatch = useContext(DispatchContext);
	return dispatch;
};

const useLessonEvent = () => {
	const dispatch = useLessonDispatch();

	const fetchLessonData = ({ bookId, lesson }) => {
		dispatch({
			type: ActionType.Fetch,
			payload: {
				bookId,
				lesson,
			},
		});
	};

	return { fetchLessonData };
};

export { LessonProvider, useLessonState, useLessonDispatch, useLessonEvent, States };
