import styled from 'styled-components';

export const UiVideoModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 1500;
`;
