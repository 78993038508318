import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { List } from 'components';
import { useBooksState } from 'providers/BooksProvider';
import { useBooksResource } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { UiHomePage, UiBookInfo, UiTab } from './HomePage.style';

/**
 * 首頁
 */

export const HomePage = () => {
	const history = useHistory();
	const state = useBooksState();
	const { getCoverSrc } = useBooksResource();

	const [currentIndex, setCurrentIndex] = useState(0);
	const controlledSwiper = useRef(null);

	const books = Object.values(state.context.books || {});
	const currentBook = books[currentIndex];
	const lessons = Object.values(currentBook?.lessons || {});

	const listItemClickHandler = (lesson) => {
		const bookId = books[currentIndex]?.bookId;
		history.push(`/${bookId}/${lesson}`);
	};

	const tabClickHandler = (index) => {
		const link=books[index].lessons.LINK
		if(books[index].lessons.LINK){
			window.open(link.url,"block")
		}else{
			setCurrentIndex(index);
		}
	};

	return (
		<UiHomePage>
			<div className="pageTitle">南一英語通</div>
			<UiTab>
				<Swiper slidesPerView="auto" freeMode={true} onSwiper={(instance) => (controlledSwiper.current = instance)}>
					{books.map((item, index) => (
						<SwiperSlide
							className={`tab${index === currentIndex ? ' is-active' : ''}`}
							key={item.name}
							onClick={() => tabClickHandler(index)}>
							<div className={`item${index === currentIndex ? ' is-active' : ''}`}>{item.name}</div>
						</SwiperSlide>
					))}
				</Swiper>
			</UiTab>
			<div className="coverWrap">
				{books.length > 0 && <img src={getCoverSrc(books[currentIndex]?.bookId)} alt={books[0].name} />}
			</div>
			<UiBookInfo>
				<div className="content">
					{lessons.map((item) => (
						<List key={item.name} label={item.label} onClick={() => listItemClickHandler(item.label)}>
							{item.name}
						</List>
					))}
				</div>
			</UiBookInfo>
		</UiHomePage>
	);
};

HomePage.propTypes = {};
