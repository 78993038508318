import { useEffect, useRef, useState } from 'react';

const useMouse = (ref) => {
	const frame = useRef(0);
	const [state, setState] = useState({
		docX: 0, // Mouse position in document
		docY: 0,
		posX: 0, // Mouse position in element
		posY: 0,
		elX: 0, // Element position
		elY: 0,
		elH: 0, // Element dimensions`
		elW: 0,
	});
	useEffect(() => {
		const moveHandler = (event) => {
			event.preventDefault();
			cancelAnimationFrame(frame.current);

			frame.current = requestAnimationFrame(() => {
				if (ref && ref.current) {
					const { pageX, pageY } = event.type === 'touchmove' ? event.touches[0] : event;
					const { left, top, width: elW, height: elH } = ref.current.getBoundingClientRect();
					const posX = left + window.scrollX;
					const posY = top + window.scrollY;
					const elX = pageX - posX;
					const elY = pageY - posY;
					setState({
						docX: event.pageX,
						docY: event.pageY,
						posX,
						posY,
						elX,
						elY,
						elH,
						elW,
					});
				}
			});
		};

		document.addEventListener('mousemove', moveHandler);
		document.addEventListener('touchmove', moveHandler);

		return () => {
			cancelAnimationFrame(frame.current);
			document.removeEventListener('mousemove', moveHandler);
			document.removeEventListener('touchmove', moveHandler);
		};
	}, [ref]);

	return state;
};

export default useMouse;
