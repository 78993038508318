import React from 'react';
import PropTypes from 'prop-types';
import { UiTipsContent, UiImageBox, UiContentBox } from './TipsContent.style';

/**
 * 操作提示內容
 */

export const TipsContent = ({ content, imgSrc }) => {
	return (
		<UiTipsContent>
			<UiImageBox mb={4}>
				<img src={imgSrc} alt={content} />
			</UiImageBox>
			<UiContentBox color="white">{content}</UiContentBox>
		</UiTipsContent>
	);
};

TipsContent.propTypes = {
	content: PropTypes.node,
	imgSrc: PropTypes.string,
};
