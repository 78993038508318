import styled from 'styled-components';

export const UiVideoPlayer = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 1000;

	.video {
		position: relative;
		display: flex;
		justify-content: space-between;
		height: 100%;
		flex: 1 0 75%;
		flex-direction: column;

		video {
			width: 100%;
			height: calc(100% - 70px);

			&::-webkit-media-controls {
				display: none !important;
			}

			&::-webkit-media-controls-enclosure {
				display: none !important;
			}

			&::-webkit-media-text-track-display {
				display: none;
			}
		}
	}

	.controlBar {
		position: relative;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		width: 100%;
		height: 60px;
		z-index: 1005;

		.left {
			display: flex;
			align-items: center;
		}

		.right {
			display: flex;
			align-items: center;
		}

		.processBar {
			left: 10px;
			width: calc(100% - 20px);
		}
	}
`;
