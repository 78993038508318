import styled from 'styled-components';

export const UiOverlay = styled.div`
	display: ${({ isActive }) => (isActive ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10;
`;
