import axios from 'axios';

export const getVocabulary = ({ bookId, lesson }) => {
	return axios
		.get(`/books/${bookId}/${lesson}/vocabulary.json`, {
			contentType: 'application/json',
			timeout: 1000,
		})
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.error(error);
			return {
				contents: [],
			};
		});
};

export const getDialogue = ({ bookId, lesson }) => {
	return axios
		.get(`/books/${bookId}/${lesson}/dialogue.json`, {
			contentType: 'application/json',
			timeout: 1000,
		})
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			return {
				video: {
					mp4: '',
					en: '',
					ch: '',
				},
				audio: {
					mp3: '',
					subtitles: '',
				},
				contents: [],
			};
		});
};

export const getRead = ({ bookId, lesson }) => {
	return axios
		.get(`/books/${bookId}/${lesson}/read.json`, {
			contentType: 'application/json',
			timeout: 1000,
		})
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.error(error);
			return {
				video: {
					mp4: '',
					en: '',
					ch: '',
				},
				audio: {
					mp3: '',
					subtitles: '',
				},
				contents: [],
			};
		});
};
