import styled from 'styled-components';
import { variant } from 'styled-system';

export const UiStudyMethod = styled.div`
	display: flex;
`;

export const UiGrid = styled.div`
	width: 50%;

	& + & {
		margin-left: 16px;
	}
`;

export const UiMethodButton = styled.div`
	display: flex;
	padding: 24px 20px;
	background-repeat: no-repeat;
	border-radius: 10px;

	& + & {
		margin-top: 16px;
	}

	> .title {
		font-size: 20px;
		color: #fff;
		line-height: 24px;
	}

	&.disable {
		&.light {
			background-color: #d5d7de;

			.title {
				color: #f2f4f8;
			}
		}

		&.dark {
			background-color: #a1a4b1;

			.title {
				color: #d5d7de;
			}
		}
		${variant({
			variants: {
				word: {
					backgroundImage: "url('/images/method-word-disable.png')",
				},
				dialogue: {
					backgroundImage: "url('/images/method-dialogue-disable.png')",
				},
				sentences: {
					backgroundImage: "url('/images/method-sentences-disable.png')",
				},
				read: {
					backgroundImage: "url('/images/method-word-disable.png')",
				},
			},
		})}
	}

	${variant({
		variants: {
			word: {
				justifyContent: 'flex-end',
				height: '120px',
				backgroundImage: "url('/images/method-word.png')",
				backgroundPosition: 'left bottom',
				backgroundSize: '92px 76px',
				backgroundColor: 'primary',
			},
			dialogue: {
				justifyContent: 'flex-end',
				height: '144px',
				backgroundImage: "url('/images/method-dialogue.png')",
				backgroundPosition: 'left bottom',
				backgroundSize: '72px 88px',
				backgroundColor: 'supporting',
			},
			sentences: {
				justifyContent: 'flex-start',
				height: '144px',
				backgroundImage: "url('/images/method-sentences.png')",
				backgroundPosition: 'right bottom',
				backgroundSize: '88px 100px',
				backgroundColor: 'supporting',
			},
			read: {
				justifyContent: 'flex-start',
				height: '120px',
				backgroundImage: "url('/images/method-read.png')",
				backgroundPosition: 'right bottom',
				backgroundSize: '72px 80px',
				backgroundColor: 'primary',
			},
		},
	})}
`;
