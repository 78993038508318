import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { UiListButton } from './ListButton.style';

import { ReactComponent as Quiz } from 'assets/icon/quiz.svg';
import { ReactComponent as Animation } from 'assets/icon/animation.svg';
import { ReactComponent as AnimationDisable } from 'assets/icon/animation-disable.svg';
import { ReactComponent as Wordgame } from 'assets/icon/wordgame.svg';
import { ReactComponent as Goldenmind } from 'assets/icon/goldenmind.svg';

/**
 * 在這邊描述這個組件
 */

const iconMap = {
	animation: <Animation />,
	disable: <AnimationDisable />,
	quiz: <Quiz />,
	wordgame: <Wordgame />,
	goldenmind: <Goldenmind />,
};

export const ListButton = ({ name, title, clickHandler = () => { }, src = '', disable = false }) => {
	const onClickHandler = (event) => {
		if (src === '') {
			event.preventDefault();
		}
		if (disable) return;
		name !== 'animation' && window.open(src, 'blank')
		clickHandler();
	};
	return (
		<UiListButton
			className={`${disable ? 'disable' : ''}`}
			// href={src}
			// target="_blank"
			onClick={(event) => onClickHandler(event)}>
			<div className="icon">{disable ? iconMap['disable'] : iconMap[name]}</div>
			<div className="title">{title}</div>
			<div className="leadIcon">
				<Icon name="chevronRight" />
			</div>
		</UiListButton>
	);
};

ListButton.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string,
	clickHandler: PropTypes.func,
	src: PropTypes.string,
	disable: PropTypes.bool,
};
