import React from 'react';
import AppRoutes from 'routes';
import { BooksProvider } from 'providers/BooksProvider';
import { LessonProvider } from 'providers/LessonProvider';

const App = () => {
	return (
		<BooksProvider>
			<LessonProvider>
				<AppRoutes />
			</LessonProvider>
		</BooksProvider>
	);
};
export default App;
