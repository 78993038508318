import { Machine, assign } from 'xstate';
import { getVocabulary, getDialogue, getRead } from 'services/api/lesson';

export const States = {
	Idle: 'Idle',
	Pending: 'Pending',
	Resolved: 'Resolved',
	Rejected: 'Rejected',
};

export const ActionType = {
	Fetch: 'Fetch',
};

export const LessonMachine = Machine({
	id: 'LessonMachine',
	initial: States.Idle,
	context: {
		vocabulary: { contents: [] },
		read: { contents: [], video: '', audio: '', vvt: '' },
		dialogue: { contents: [], video: '', audio: '', vvt: '' },
	},
	states: {
		[States.Idle]: {
			on: {
				[ActionType.Fetch]: {
					target: States.Pending,
				},
			},
		},
		[States.Pending]: {
			invoke: {
				id: 'fetchLessonContent',
				src: async (ctx, event) => {
					const { payload } = event;
					const params = {
						bookId: payload.bookId,
						lesson: payload.lesson,
					};

					return Promise.all([getVocabulary(params), getDialogue(params), getRead(params)]).then(
						([vocabulary, dialogue, read]) => {
							return {
								vocabulary,
								dialogue,
								read,
							};
						},
					);
				},
				onDone: {
					target: States.Resolved,
					actions: assign({
						vocabulary: (ctx, event) => event.data.vocabulary,
						dialogue: (ctx, event) => event.data.dialogue,
						read: (ctx, event) => event.data.read,
					}),
				},
				onError: {
					target: States.Rejected,
				},
			},
		},
		[States.Resolved]: {
			on: {
				[ActionType.Fetch]: {
					target: States.Pending,
				},
			},
		},
		[States.Rejected]: {},
	},
});
