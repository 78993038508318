import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import GlobalStyle from 'styles';
import ResetStyle from 'styles/resetStyle';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'providers/ThemeProvider';
const root = document.getElementById('root');

const Main = () => (
	<ThemeProvider>
		<ResetStyle />
		<GlobalStyle />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ThemeProvider>
);

ReactDOM.render(Main(), root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
