import React from 'react';
import PropTypes from 'prop-types';
import { UiKKSound } from './KKSound.style';

const reg = /i|ɪ|e|ɛ|æ|ɑ|o|ɔ|u|ᴜ|ʌ|ə|ɚ|ɝ|aɪ|aᴜ|ɔɪ/g;

/**
 * KK音標組件
 */

export const KKSound = ({ children }) => {
	return (
		<UiKKSound
			dangerouslySetInnerHTML={{
				__html: children.replace(reg, (word) => `<span>${word}</span>`),
			}}
		/>
	);
};

KKSound.propTypes = {
	children: PropTypes.string,
};
