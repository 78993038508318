import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimesCircle,
	faPlay,
	faPause,
	faVolumeMute,
	faVolumeDown,
	faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import { UiVideoIcon } from './VideoIcon.style';

/**
 * 在這邊描述這個組件
 */

const fontawesomeIconMap = {
	timesCircle: faTimesCircle,
	play: faPlay,
	pause: faPause,
	volumeMute: faVolumeMute,
	volumeDown: faVolumeDown,
	volumeUp: faVolumeUp,
};

export const VideoIcon = ({ className, name }) => {
	return (
		<UiVideoIcon>
			<FontAwesomeIcon icon={fontawesomeIconMap[name]} className={className} />
		</UiVideoIcon>
	);
};

VideoIcon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
};
