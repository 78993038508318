import styled from 'styled-components';
import { Box } from 'components';

export const UiLessonPage = styled.div`
	display: block;
`;

export const UiBody = styled(Box)`
	.lessonTitle {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		text-align: center;
	}

	.studyMethodBlock {
		margin-top: 20px;
	}

	.listBtnBlock {
		margin-top: 20px;
	}
`;
