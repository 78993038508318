import styled from 'styled-components';
import { variant } from 'styled-system';

export const UiAudioButton = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: 30px;

	audio {
		display: none;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 140%;
		height: 140%;
	}

	${variant({
		variants: {
			primary: {
				backgroundColor: 'inverse',
			},
			inverse: {
				backgroundColor: 'primary',
			},
		},
	})}

	${variant({
		prop: 'size',
		variants: {
			large: {
				width: '100%',
				height: '5em',
			},
			medium: {
				width: '5em',
				height: '3em',
			},
			small: {
				width: '40px',
				height: '40px',
			},
		},
	})}
`;
