import styled from 'styled-components';
import { variant } from 'styled-system';

export const UiIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	> svg path {
		${variant({
			variants: {
				primary: {
					fill: 'primary',
				},
				inverse: {
					fill: 'inverse',
				},
				headlines: {
					fill: 'headlines',
				},
			},
		})}
	}
`;
