import React from 'react';
import PropTypes from 'prop-types';
import { UiButton } from './Button.style';

/**
 * 在這邊描述這個組件
 */

export const Button = ({ text, onClick, variant }) => {
	return (
		<UiButton variant={variant} onClick={onClick}>
			{text}
		</UiButton>
	);
};

Button.propTypes = {
	text: PropTypes.string,
	onClick: PropTypes.func,
	variant: PropTypes.string,
};
