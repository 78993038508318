import { createGlobalStyle } from 'styled-components';

import OpenSansRegular from '../assets/fonts/Open_Sans/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf';
import OpenSansBold from '../assets/fonts/Open_Sans/OpenSans-Bold.ttf';
import NotoSansTCRegular from '../assets/fonts/Noto_Sans_TC/NotoSansTC-Regular.otf';
import NotoSansTCBold from '../assets/fonts/Noto_Sans_TC/NotoSansTC-Bold.otf';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    src: url('${OpenSansRegular}') format('truetype');
  }

	@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    src: url('${OpenSansSemiBold}') format('truetype');
  }

	@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    src: url('${OpenSansBold}') format('truetype');
  }

@font-face {
    font-family: 'NotoSansTC';
    font-style: normal;
    font-weight: normal;
    src: url('${NotoSansTCRegular}') format('opentype');
  }

	@font-face {
    font-family: 'NotoSansTC';
    font-style: normal;
    font-weight: 700;
    src: url('${NotoSansTCBold}') format('opentype');
  }

body {
		margin: 0;
		padding: 0;
	}

	html,
body {
    font-family: 'OpenSans', 'NotoSansTC', Arial, sans-serif;
  }

	* {
		box-sizing: border-box;
	}

	#root {
		width: 100vw;
		height: 100vh;
	}

`;

export default GlobalStyle;
