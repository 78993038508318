import styled from 'styled-components';

export const UiProcessBar = styled.div`
	position: absolute;
	top: 0;
	left: 0px;
	transform: translateY(-100%);
	width: 100%;
	height: 6px;
	background-color: #ddd;
	cursor: pointer;
	color: #fff;
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		width: 100%;
		height: 200%;
		background-color: transparent;
		z-index: 0;
	}
	.processUsage {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: #cc0000;
		transition: 0.1s;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #cc0000;
			transform: translateY(-50%) translateX(50%);
		}
	}
	.range {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
		opacity: 0;
		cursor: pointer;
	}
	.timeTooptips {
		opacity: 0;
		position: absolute;
		bottom: 100%;
		transform: translateX(-50%) translateY(-5px);
		padding: 5px;
		background-color: rgba(0, 0, 0, 0.85);
		color: #fff;
		font-size: 14px;
		font-family: Arial;
	}
	&:hover .timeTooptips {
		opacity: 1;
	}
`;
