import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { useBooksResource } from 'utils';
import { Box, AudioButton, Overlay, Icon, TipsContent } from 'components';
import { useLessonState } from 'providers/LessonProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { UiCardPopup, UiCard, UiCardHeader, UiCardContent } from './CardPopup.style';

import flipTips from 'assets/images/flipTips.svg';

/**
 * 在這邊描述這個組件
 */

const LocalStorageKey = 'isFirstTimeInCardPopup';

export const useCardPopup = (defaultState) => {
	const [cardPopupIsActive, setCardPopupIsActive] = useState(defaultState);
	const [inCardPopupProp, setInCardPopupProp] = useState(false);

	const closeCardPopup = useCallback(() => {
		setCardPopupIsActive(false);
		setInCardPopupProp(false);
	}, []);

	const openCardPopup = useCallback(() => {
		setCardPopupIsActive(true);
		setInCardPopupProp(true);
	}, []);

	return { cardPopupIsActive, inCardPopupProp, closeCardPopup, openCardPopup };
};

export const CardPopup = ({ audioType, currentIndex, in: inCardPopupProp, onBackHandler, onSwiperHandler }) => {
	const { lessonHeader, getAudioSource } = useBooksResource();
	const lessonState = useLessonState();
	const lessonSentenceInfo = lessonState.context[audioType].contents || [];
	const { overlayIsActive, closeOverlay, openOverlay } = Overlay.useOverlay(false);

	const controlledSwiper = useRef(null);
	const duration = 300;
	const transitionPopupStyles = {
		entering: { bottom: '-100vh' },
		entered: { bottom: '0' },
		exiting: { bottom: '-100vh' },
		exited: { bottom: '-100vh' },
	};
	const defaultStyle = {
		transition: `bottom ${duration}ms ease-in-out`,
	};

	const audioSource = getAudioSource(lessonSentenceInfo[currentIndex]?.mp3);

	const backHandler = () => {
		onBackHandler();
	};

	const onSwiperSliderHandler = (swiper) => {
		onSwiperHandler(swiper.activeIndex);
	};

	useEffect(() => {
		if (inCardPopupProp && !(localStorage.getItem(LocalStorageKey) === 'false')) {
			localStorage.setItem(LocalStorageKey, false);
			openOverlay();
		}
	}, [inCardPopupProp]);

	useEffect(() => {
		if (currentIndex === null) return;
		controlledSwiper.current.slideTo(currentIndex);
	}, [currentIndex]);

	return (
		<Transition in={inCardPopupProp} timeout={duration}>
			{(state) => (
				<>
					<UiCardPopup
						style={{
							...defaultStyle,
							...transitionPopupStyles[state],
						}}>
						<Overlay onClick={closeOverlay} isActive={overlayIsActive}>
							<Box height="100%" display="flex" justifyContent="center" alignItems="center">
								<TipsContent imgSrc={flipTips} content="上下滑動切換上下句翻譯" />
							</Box>
						</Overlay>
						<UiCard>
							<UiCardHeader>
								<div className="backAction" onClick={backHandler}>
									<Icon name="chevronLeft" variant="inverse" />
									<div className="backText">{lessonHeader}</div>
								</div>
								<div className="popupTitle">單句翻譯</div>
							</UiCardHeader>
							<UiCardContent>
								<Swiper
									direction="vertical"
									onSwiper={(instance) => (controlledSwiper.current = instance)}
									onActiveIndexChange={onSwiperSliderHandler}>
									{lessonSentenceInfo.map((item) => (
										<SwiperSlide key={item.mp3}>
											<div className="sentenceGroup">
												<div className="sentence en">{item.en}</div>
												<div className="sentence ch">{item.ch}</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</UiCardContent>
							<Box display="flex" justifyContent="center" marginTop={35} marginBottom={24}>
								<AudioButton variant="primary" src={audioSource} />
							</Box>
						</UiCard>
					</UiCardPopup>
				</>
			)}
		</Transition>
	);
};

CardPopup.propTypes = {
	cueId: PropTypes.string,
	audioType: PropTypes.string,
	currentIndex: PropTypes.number,
	in: PropTypes.bool,
	onBackHandler: PropTypes.func,
	onSwiperHandler: PropTypes.func,
};
