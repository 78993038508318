import styled from 'styled-components';
import { Card } from 'components';

const commonStyle = `
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transition: 0.5s;
`;

export const UiFrontCard = styled(Card)`
	transform: rotateY(0deg);
	${commonStyle}
`;

export const UiBackCard = styled(Card)`
	transform: rotateY(180deg);
	${commonStyle}
`;

export const UiFlipCard = styled.div`
	position: relative;
	transform-style: preserve-3d;
	perspective: 1000px;
	transition: 0.5s;
	width: 100%;
	height: 100%;

	transform: ${({ isRotate }) => (isRotate ? 'rotateY(180deg)' : 'rotateY(0)')};
	transition: 0.5s;
`;
