import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { UiOverlay } from './Overlay.style';

/**
 * 半透明黑底 Overlay
 */

export const useOverlay = (defaultState) => {
	const [overlayIsActive, setOverlayIsActive] = useState(defaultState);

	const closeOverlay = useCallback(() => {
		setOverlayIsActive(false);
	}, []);

	const openOverlay = useCallback(() => {
		setOverlayIsActive(true);
	}, []);

	return { overlayIsActive, closeOverlay, openOverlay };
};

export const Overlay = ({ children, isActive, onClick }) => {
	return (
		<UiOverlay onClick={onClick} isActive={isActive}>
			{children}
		</UiOverlay>
	);
};

Overlay.propTypes = {
	isActive: PropTypes.bool,
	children: PropTypes.node,
	onClick: PropTypes.func,
};
