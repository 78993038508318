import { HomePage, LessonPage, VocabularyPage, DialoguePage, ReadPage } from 'views';
import { MainLayout } from 'layouts';
import { PageName } from 'routes/constants';

const routes = [
	{
		path: '/',
		component: MainLayout,
		routes: [
			{
				path: '/',
				component: HomePage,
				name: PageName.Home,
				exact: true,
			},
			{
				path: '/:bookId/:lesson',
				component: LessonPage,
				name: PageName.Lesson,
				exact: true,
			},
			{
				path: '/:bookId/:lesson/vocabulary',
				component: VocabularyPage,
				name: PageName.Vocabulary,
				exact: true,
			},
			{
				path: '/:bookId/:lesson/example',
				component: VocabularyPage,
				name: PageName.Example,
				exact: true,
			},
			{
				path: '/:bookId/:lesson/dialogue',
				component: DialoguePage,
				name: PageName.dialogue,
				exact: true,
			},
			{
				path: '/:bookId/:lesson/read',
				component: ReadPage,
				name: PageName.Read,
				exact: true,
			},
		],
	},
];
export default routes;
