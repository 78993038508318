import styled from 'styled-components';

export const UiSubtitles = styled.div`
	position: absolute;
	left: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	font-size: 8vmin;
	font-family: Arial, Microsoft JhengHei, 微軟正黑體, -apple-system, BlinkMacSystemFont;
	text-align: center;
	line-height: 1.2;
	font-weight: bold;
	bottom: 68px;
`;
