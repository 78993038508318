import styled from 'styled-components';
import { layout } from 'styled-system';

export const UiMainLayout = styled.div`
	display: block;
	width: 100%;
	height: 100%;
`;

export const UiContainer = styled.div`
	margin: 0 auto;
	width: 100%;
	height: 100%;
	${layout}
`;
