import styled from 'styled-components';

export const UiVolumeBarWrapper = styled.div`
	display: none;
	.range {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
		opacity: 0;
		cursor: pointer;
	}
`;

export const UiVolumeBar = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 0;
	transition: 0.3s;
	overflow: hidden;
	cursor: pointer;
	transition: 0.3s;
	&:before {
		content: '';
		width: 100%;
		height: 10px;
		background-color: #ccc;
	}
`;

export const UiVolumeUsage = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	background-color: #cc0000;
	width: 70%;
	height: 10px;
`;
