import React from 'react';
import PropTypes from 'prop-types';
import { UiList } from './List.style';

/**
 * 課次列表
 */

export const List = ({ label, children, onClick }) => {
	return (
		<UiList onClick={onClick}>
			<div className="label">{label}</div>
			<div className="title">{children}</div>
		</UiList>
	);
};

List.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
};
