import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { UiAudioButton } from './AudioButton.style';

/**
 * 音檔播放按鈕
 */

export const AudioButton = ({ src, variant, size = 'medium', onClick = () => {} }) => {
	const audioRef = useRef();

	const clickHandler = (event) => {
		if (src) {
			audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause();
			event.stopPropagation();
		}
		onClick();
	};

	return (
		<UiAudioButton onClick={clickHandler} variant={variant} size={size}>
			{src && <audio ref={audioRef} src={src} playsInline crossOrigin="anonymous" />}
			<Icon name="volumeUp" variant={variant} />
		</UiAudioButton>
	);
};

AudioButton.propTypes = {
	src: PropTypes.string,
	onClick: PropTypes.func,
	variant: PropTypes.oneOf(['primary', 'inverse']),
	size: PropTypes.oneOf(['large', 'medium', 'small']),
};
