import { useMemo } from 'react';
import { useBooksState } from 'providers/BooksProvider/index';
import { useParams } from 'react-router-dom';

const BASE_URL = process.env.PUBLIC_URL;

export const getCoverSrc = (bookId) => {
	return `${BASE_URL}/books/${bookId}/cover.jpg`;
};

const lessonMap = {
	L1: 'Lesson 1',
	L2: 'Lesson 2',
	L3: 'Lesson 3',
	L4: 'Lesson 4',
	L5: 'Lesson 5',
	L6: 'Lesson 6',
};

export const useBooksResource = () => {
	const { bookId, lesson } = useParams();
	const {
		context: { books },
	} = useBooksState();
	const currentLesson = books[bookId]?.lessons[lesson] || {};
	const { label, name, quizHref } = currentLesson;
	const getAudioSource = (fileName) => `${BASE_URL}/books/${bookId}/${lesson}/audio/${fileName}`;
	const getCoverSrc = (bookId) => `${BASE_URL}/books/${bookId}/cover.jpg`;

	const lessonHeader = useMemo(() => lessonMap[label], [label]);
	const lessonTitle = useMemo(() => name, [name]);
	const lessonQuizHref = useMemo(() => quizHref, [quizHref]);

	const getLessonVideoInfo = (fileInfo) => {
		return {
			video: `${BASE_URL}/books/${bookId}/${lesson}/video/${fileInfo.mp4}`,
			subtitles: [
				{
					language: 'en',
					src: `${BASE_URL}/books/${bookId}/${lesson}/video/${fileInfo.en}`,
				},
				{
					language: 'zh-TW',
					src: `${BASE_URL}/books/${bookId}/${lesson}/video/${fileInfo.ch}`,
				},
			],
		};
	};

	const getLessonAudioInfo = (audioInfo) => {
		if (!audioInfo) return {};
		return {
			mp3: `${BASE_URL}/books/${bookId}/${lesson}/audio/${audioInfo.mp3}`,
			subtitles: `${BASE_URL}/books/${bookId}/${lesson}/audio/${audioInfo.subtitles}`,
		};
	};

	return {
		getCoverSrc,
		getAudioSource,
		lessonHeader,
		lessonTitle,
		lessonQuizHref,
		getLessonVideoInfo,
		getLessonAudioInfo,
	};
};
