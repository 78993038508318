import styled from 'styled-components';

export const UiVocabularyCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	flex-direction: column;

	.word {
		display: flex;
		font-size: 32px;
		line-height: 1.5em;
		font-weight: 600;
		color: ${({ theme }) => theme.colors.headlines};
	}

	.partOfSpeech {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 2px 1em;
		min-width: 30px;
		font-size: 16px;
		font-weight: 600;
		color: ${({ theme }) => theme.colors.white};
		background-color: ${({ theme }) => theme.colors.supporting};
		border-radius: 14px;
	}

	.translation {
		display: inline-flex;
		align-items: center;
		margin-left: 0.5em;
		color: ${({ theme }) => theme.colors.paragraphs};
	}
`;
