import styled from 'styled-components';
import { Box } from 'components';

export const Card = styled(Box)`
	/* display: block; */
	padding: 1.25em;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;
