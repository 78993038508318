import React from 'react';
import PropTypes from 'prop-types';
import { UiAppBar } from './AppBar.style';

/**
 * AppBar
 */

export const AppBar = ({ variant, children }) => {
	return <UiAppBar variant={variant}>{children}</UiAppBar>;
};

AppBar.propTypes = {
	variant: PropTypes.string,
	children: PropTypes.element,
};
