import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ControlButton from 'components/Player/ControlButton';
import { useBoolean } from 'utils/hooks/boolean';
import useMouse from 'utils/hooks/mouse';
import VideoIcon from 'components/VideoIcon';
import { UiVolumeBarWrapper, UiVolumeBar, UiVolumeUsage } from './VolumeBar.style';

export const VolumeBar = ({
	state,
	controls,
	onChange,
	className,
	hoverable = true,
	width = 100,
	onVolumeBarActiveStateChange = () => {},
}) => {
	const [
		isShowVolumeBar,
		{ toggle: setVolumeBarToggle, setFalse: setVolumeBarDeactive, setTrue: setVolumeBarActive },
	] = useBoolean();

	const volumeBarRef = useRef();
	const { elX, elW } = useMouse(volumeBarRef);

	const changeHandler = useCallback(
		(e) => {
			onChange(elX / elW);
			controls.unmute();
		},
		[elW, elX, onChange],
	);

	const onClickHandler = useCallback(() => {
		if (hoverable) {
			return (state.volume > 0 && !state.muted) || !state.muted ? controls.mute() : controls.unmute();
		} else {
			setVolumeBarToggle();
		}
	}, [controls, hoverable, setVolumeBarToggle, state.muted, state.volume]);

	const onMouseEnterHandler = useCallback(() => {
		hoverable && setVolumeBarActive();
	}, [hoverable, setVolumeBarActive]);

	const onMouseLeaveHandler = useCallback(() => {
		hoverable && setVolumeBarDeactive();
	}, [hoverable, setVolumeBarDeactive]);

	useEffect(() => {
		onVolumeBarActiveStateChange(isShowVolumeBar);
	}, [isShowVolumeBar, onVolumeBarActiveStateChange]);

	return (
		<UiVolumeBarWrapper>
			<ControlButton onClick={() => onClickHandler()}>
				{(state.volume === 0 || state.muted) && <VideoIcon type="text" name="volumeMute" />}
				{state.volume > 0 && state.volume < 0.7 && !state.muted && <VideoIcon type="text" name="volumeDown" />}
				{state.volume >= 0.7 && !state.muted && <VideoIcon type="text" name="volumeUp" />}
			</ControlButton>
			<UiVolumeBar
				ref={volumeBarRef}
				style={{ width: `${width}px` }}
				onMouseEnter={() => onMouseEnterHandler()}
				onMouseLeave={() => onMouseLeaveHandler()}
				onClick={(e) => changeHandler(e)}>
				<UiVolumeUsage style={{ width: state.muted ? 0 : `${(state.volume / 1) * 100}%` }} />
			</UiVolumeBar>
		</UiVolumeBarWrapper>
	);
};

VolumeBar.propTypes = {
	state: PropTypes.object,
	controls: PropTypes.object,
	onChange: PropTypes.func,
	hoverable: PropTypes.bool,
	className: PropTypes.string,
	width: PropTypes.number,
	onVolumeBarActiveStateChange: PropTypes.func,
};
