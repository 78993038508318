import styled from 'styled-components';

export const UiCardPopup = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100vh;
	z-index: 2;

	.swiper-container {
		width: 100%;
		height: 100%;
	}
`;

export const UiCard = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - 20px);
	background-color: ${({ theme }) => theme.colors.supporting};
	border-radius: 10px 10px 0 0;

	&::before {
		position: absolute;
		top: -10px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: calc(100% - 32px);
		height: 10px;
		background-color: #000;
		border-radius: 10px 10px 0 0;
		content: '';
	}
`;

export const UiCardHeader = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 17px;
	height: 42px;
	color: #fff;

	.backAction {
		position: absolute;
		display: flex;
		align-items: center;
		justify-self: flex-start;
		font-weight: 600;
	}

	> .popupTitle {
		width: 100%;
		font-size: 17px;
		font-weight: 600;
		text-align: center;
		line-height: 22px;
	}
`;

export const UiCardContent = styled.div`
	overflow: hidden;
	margin: 24px;
	padding: 24px;
	height: calc(100% - 42px - 48px - 48px - 17px - 35px);
	background-color: #fff;
	border-radius: 10px;

	.sentenceGroup {
		display: flex;
		justify-content: center;
		height: 100%;
		flex-direction: column;
	}

	.sentence {
		padding: 32px 0;
		font-size: 18px;
		line-height: 32px;
		text-align: left;

		+ .sentence {
			border-top: 1px solid #c8c7cc;
		}

		&.en {
			font-weight: bold;
		}
	}
`;
