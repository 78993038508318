import styled from 'styled-components';

export const UiExampleCard = styled.div`
	overflow-y: auto;
	max-height: 100%;

	.vocabularyCard {
		align-items: flex-start;
	}

	.exampleContainer {
		margin-top: 16px;
		padding: 16px 0;
		border-top: 0.5px solid #c8c7cc;

		.example {
			font-size: 16px;
			font-weight: 600;
			color: ${({ theme }) => theme.colors.headlines};
			line-height: 2em;
		}

		.translation {
			font-size: 16px;
			color: ${({ theme }) => theme.colors.paragraphs};
			line-height: 1.5em;
		}
	}

	.audioButton {
		position: absolute;
		bottom: 12px;
		right: 24px;
	}
`;
