import styled from 'styled-components';

export const UiList = styled.a`
	display: flex;
	padding: 6px 0;
	text-decoration: none;

	.label {
		margin-top: 6px;
		width: 40px;
		height: 20px;
		font-size: 14px;
		text-align: center;
		color: #fff;
		background-color: ${({ theme }) => theme.colors.primary};
		border-radius: 12px;
		line-height: 20px;
		flex: 0 0 40px;
	}

	.title {
		margin-left: 16px;
		color: ${({ theme }) => theme.colors.paragraphs};
		line-height: 32px;
	}
`;
