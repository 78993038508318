import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UiFlipCard, UiFrontCard, UiBackCard } from './FlipCard.style';

/**
 * 可翻面卡牌組件
 */

export const Side = {
	Front: 'Front',
	Back: 'Back',
};

export const FlipCard = ({ front, back, defaultSide = Side.Front }) => {
	const [isRotate, setRotate] = useState(defaultSide === Side.Back);
	const toggle = () => {
		setRotate(!isRotate);
	};

	return (
		<UiFlipCard onClick={toggle} isRotate={isRotate}>
			<UiFrontCard>{front}</UiFrontCard>
			<UiBackCard>{back}</UiBackCard>
		</UiFlipCard>
	);
};

FlipCard.propTypes = {
	front: PropTypes.node,
	back: PropTypes.node,
	defaultSide: PropTypes.oneOf([Side.Front, Side.Back]),
};
