import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'components/VideoPlayer';
import { UiVideoModal } from './VideoModal.style';
/**
 * 在這邊描述這個組件
 */

export const useVideoModal = (defaultState) => {
	const [videoModalIsActive, setVideoModalIsActive] = useState(defaultState);

	const openVideoModal = useCallback(() => {
		setVideoModalIsActive(true);
	}, []);

	const closeVideoModal = useCallback(() => {
		setVideoModalIsActive(false);
	}, []);

	return { videoModalIsActive, openVideoModal, closeVideoModal };
};

export const VideoModal = ({ bookId, videoInfo, isActive, onClick, onCloseVideoModal }) => {
	return (
		<UiVideoModal onClick={onClick} isActive={isActive}>
			{isActive && (
				<VideoPlayer
					src={videoInfo.video}
					subtitles={videoInfo.subtitles}
					key="VideoPlayer"
					bookId={bookId}
					onCloseHandler={onCloseVideoModal}
				/>
			)}
		</UiVideoModal>
	);
};

VideoModal.propTypes = {
	bookId: PropTypes.string,
	videoInfo: PropTypes.object,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
	onCloseVideoModal: PropTypes.func,
};
