import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Box } from 'components';
import { UiPopup, UiPopupBox } from './Popup.style';

/**
 * 在這邊描述這個組件
 */

export const usePopup = (defaultState) => {
	const [popupIsActive, setPopupIsActive] = useState(defaultState);
	const [inProp, setInProp] = useState(false);

	const closePopup = useCallback(() => {
		setPopupIsActive(false);
		setInProp(false);
	}, []);

	const openPopup = useCallback(() => {
		setPopupIsActive(true);
		setInProp(true);
	}, []);

	return { popupIsActive, inProp, closePopup, openPopup };
};

export const Popup = ({ children, in: inProp }) => {
	const duration = 300;

	const transitionPopupStyles = {
		entering: { display: 'block' },
		exited: { display: 'none' },
	};

	const defaultStyle = {
		transition: `bottom ${duration}ms ease-in-out`,
		bottom: '-100%',
	};

	const transitionStyles = {
		entering: { bottom: '-100%' },
		entered: { bottom: '0' },
		exiting: { bottom: '-100%' },
		exited: { bottom: '-100%' },
	};

	return (
		<Transition in={inProp} timeout={duration}>
			{(state) => (
				<UiPopup
					style={{
						...transitionPopupStyles[state],
					}}>
					<Box height="100%" display="flex" justifyContent="center" alignItems="flex-end">
						<UiPopupBox
							style={{
								...defaultStyle,
								...transitionStyles[state],
							}}>
							{children}
						</UiPopupBox>
					</Box>
				</UiPopup>
			)}
		</Transition>
	);
};

Popup.propTypes = {
	isActive: PropTypes.bool,
	children: PropTypes.node,
	in: PropTypes.bool,
};
