import styled from 'styled-components';
import { rgba } from 'polished';
import { variant } from 'styled-system';

export const UiButton = styled.div`
	display: block;
	padding: 10px;
	text-align: center;
	color: #fff;
	border-radius: 10px;
	line-height: 32px;

	& + & {
		margin-top: 16px;
	}

	${variant({
		variants: {
			primary: {
				backgroundColor: 'primary',
			},
			disabled: {
				backgroundColor: '#F2F4F8',
				color: '#D5D7DE',
			},
		},
	})}

	&:active {
		${({ theme }) =>
			variant({
				variants: {
					primary: {
						backgroundColor: rgba(theme.colors.primary, 0.7),
					},
					disabled: {
						backgroundColor: '#F2F4F8',
						color: '#D5D7DE',
					},
				},
			})}
	}

	&:disabled {
		background-color: #ccc;
	}
`;
