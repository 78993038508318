import React from 'react';
import { TextBookWrap } from 'components';
/**
 * 課文閱讀頁
 */

export const ReadPage = () => {
	return <TextBookWrap textBookType={'read'} />;
};

ReadPage.propTypes = {};
