import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ControlButton from 'components/Player/ControlButton';

export const SubtitleControl = ({ subtitles = [], onChange, value = [] }) => {
	const [selectedLang, selected] = useState({
		'zh-TW': value.includes('zh-TW'),
		en: value.includes('en'),
	});

	useEffect(() => {
		let langValues = [];
		Object.keys(selectedLang).forEach((lang) => {
			selectedLang[lang] && langValues.push(lang);
		});
		onChange(langValues);
	}, [onChange, selectedLang]);

	const handler = useCallback(
		(lang) => {
			selected({ ...selectedLang, [lang]: !selectedLang[lang] });
		},
		[selected, selectedLang],
	);

	return (
		<>
			{subtitles.map((subtitle) => (
				<ControlButton
					key={subtitle.language}
					active={selectedLang[subtitle.language]}
					onClick={() => handler(subtitle.language)}>
					{subtitle.language === 'en' ? '英' : '中'}
				</ControlButton>
			))}
		</>
	);
};

SubtitleControl.propTypes = {
	subtitles: PropTypes.array,
	onChange: PropTypes.func,
	value: PropTypes.array,
};
