import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	FlipCard,
	AudioButton,
	Box,
	VocabularyCard,
	ExampleCard,
	Overlay,
	LessonHeader,
	TipsContent,
} from 'components';
import { useLessonState } from 'providers/LessonProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useBooksResource } from 'utils';
import { PageName } from 'routes/constants';
import { UiVocabularyPage } from './VocabularyPage.style';

import clickTips from 'assets/images/clickTips.svg';

/**
 * 單字、單字例句 頁面
 */
const LocalStorageKey = 'isFirstTimeInVocabularyPage';

export const VocabularyPage = ({ name }) => {
	const pageTitle = name === PageName.Vocabulary ? '單字學習' : '單字例句';
	const defaultSide = name === PageName.Vocabulary ? FlipCard.Side.Front : FlipCard.Side.Back;
	const { getAudioSource, lessonHeader } = useBooksResource();
	const state = useLessonState();
	const vocabulary = state.context.vocabulary.contents || [];

	const [currentIndex, setCurrentIndex] = useState(0);
	const { overlayIsActive, closeOverlay } = Overlay.useOverlay(
		localStorage.getItem(LocalStorageKey) === 'false' ? false : true,
	);

	const slideChangeHandler = (event) => {
		setCurrentIndex(event.realIndex);
	};

	const overlayClickHandler = () => {
		localStorage.setItem(LocalStorageKey, false);
		closeOverlay();
	};

	const audioSource = getAudioSource(vocabulary[currentIndex]?.wordSound);

	return (
		<UiVocabularyPage>
			<Overlay onClick={overlayClickHandler} isActive={overlayIsActive}>
				<Box height="100%" display="flex" justifyContent="center" alignItems="center">
					<TipsContent
						imgSrc={clickTips}
						content={
							<p>
								點擊卡片翻轉單字卡
								<br />
								讓背單字更加容易！
							</p>
						}
					/>
				</Box>
			</Overlay>
			<LessonHeader title={lessonHeader} variant="inverse" />
			<div className="pageTitle">{pageTitle}</div>
			<Swiper
				onSlideChange={slideChangeHandler}
				slidesPerView={'auto'}
				loopedSlides={2}
				loop={false}
				centeredSlides={true}
				spaceBetween={10}>
				{vocabulary.map((item) => (
					<SwiperSlide key={item.word}>
						{({ isActive }) => (
							<FlipCard
								defaultSide={defaultSide}
								front={<VocabularyCard KK={item.KK} word={item.word} partOfSpeech={item.partOfSpeech} />}
								back={<ExampleCard data={item} isActive={isActive} />}
							/>
						)}
					</SwiperSlide>
				))}
			</Swiper>
			<Box display="flex" justifyContent="center" marginTop={35} marginBottom={24}>
				<AudioButton variant="primary" src={audioSource} />
			</Box>
		</UiVocabularyPage>
	);
};

VocabularyPage.propTypes = {
	name: PropTypes.string,
};
