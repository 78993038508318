import React from 'react';
import PropTypes from 'prop-types';
import { UiIcon } from './Icon.style';

import { ReactComponent as Expand } from 'assets/icon/expand.svg';
import { ReactComponent as ExpandCircle } from 'assets/icon/expand_circle.svg';
import { ReactComponent as Times } from 'assets/icon/times.svg';
import { ReactComponent as VolumeUp } from 'assets/icon/volume_up.svg';
import { ReactComponent as ChevronLeft } from 'assets/icon/chevron_left.svg';
import { ReactComponent as ChevronRight } from 'assets/icon/chevron_right.svg';
import { ReactComponent as ArrowCircle } from 'assets/icon/arrow_circle.svg';

/**
 * 在這邊描述這個組件
 */

const iconMap = {
	expand: <Expand />,
	expandCircle: <ExpandCircle />,
	times: <Times />,
	volumeUp: <VolumeUp />,
	chevronLeft: <ChevronLeft />,
	chevronRight: <ChevronRight />,
	arrowCircle: <ArrowCircle />,
};

export const Icon = ({ name, variant }) => {
	return <UiIcon variant={variant}>{iconMap[name]}</UiIcon>;
};

Icon.propTypes = {
	name: PropTypes.string,
	variant: PropTypes.oneOf(['primary', 'inverse', 'headlines']),
};
