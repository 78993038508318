import React from 'react';
import PropTypes from 'prop-types';
import { UiSubtitles } from './Subtitles.style';

const Cue = ({ text }) => <div className="cue" dangerouslySetInnerHTML={{ __html: text }} />;

Cue.propTypes = {
	text: PropTypes.string,
};

export const Subtitles = ({ activeCue, language }) => {
	return (
		<UiSubtitles>
			{language.map((lang) => activeCue[lang] && <Cue key={lang} text={activeCue[lang].text} />)}
		</UiSubtitles>
	);
};

Subtitles.propTypes = {
	activeCue: PropTypes.object,
	language: PropTypes.array,
};
