import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBooksResource } from 'utils';
import { ListButton, LessonHeader, StudyMethod, Popup, Button, Icon, VideoModal } from 'components';
import { UiLessonPage, UiBody } from './LessonPage.style';
import { useLessonEvent } from 'providers/LessonProvider/index';
import { useLessonState, States } from 'providers/LessonProvider';
import { useBooksState } from 'providers/BooksProvider';

/**
 * 單元選擇頁
 */

export const LessonPage = () => {
	const { bookId, lesson } = useParams();
	const { lessonHeader, lessonTitle, lessonQuizHref, getLessonVideoInfo } = useBooksResource();
	const { fetchLessonData } = useLessonEvent();
	const { videoModalIsActive, openVideoModal, closeVideoModal } = VideoModal.useVideoModal(false);
	const [videoModalData, setVideoModalData] = useState({});
	const { popupIsActive, inProp, closePopup, openPopup } = Popup.usePopup(false);
	const state = useLessonState();
	const bookState = useBooksState();
	const dialogueVideoFile = state.context.dialogue.video || {};
	const readVideoFile = state.context.read.video || {};
	const books = Object.values(bookState.context.books || {});
	const lessons=books.find(book=>book.bookId===bookId).lessons
	const [pageInfo,setPageInfo]=useState(lessons[lesson])

	useEffect(() => {
		if (!bookId || !lesson) return;
		fetchLessonData({ bookId, lesson });
	}, [bookId, lesson]);

	const videoInfoHandler = (type) => () => {
		const isExist = state.context[type].video.mp4 !== '';
		let videoInfo = null;

		switch (type) {
			case 'dialogue':
				videoInfo = getLessonVideoInfo(dialogueVideoFile);
				break;
			default:
			case 'read':
				videoInfo = getLessonVideoInfo(readVideoFile);
				break;
		}

		if (!isExist || !videoInfo) return;
		setVideoModalData(videoInfo);
		openVideoModal();
	};

	const animationClickHandler = () => {
		const notExist = animationFoolProofCondition();
		if (notExist) return;
		openPopup();
	};

	const foolProofCondition = (type) => {
		const isExist = state.context[type].video.mp4 !== '';

		return isExist ? 'primary' : 'disabled';
	};

	const animationFoolProofCondition = () => {
		const notExist = state.context['dialogue'].video.mp4 === '' && state.context['read'].video.mp4 === '';
		return notExist;
	};
	
	return (
		<UiLessonPage>
			<LessonHeader title={lessonHeader} variant="primary" />
			<UiBody mt={1} mb={3} ml={5} mr={5}>
				<div className="lessonTitle">{lessonTitle}</div>
				<div className="studyMethodBlock">
					<StudyMethod />
				</div>
				<div className="listBtnBlock">
					<ListButton
						name="animation"
						title="看課文動畫"
						disable={animationFoolProofCondition()}
						clickHandler={animationClickHandler}
					/>
					<ListButton name="quiz" title="做測驗" src={lessonQuizHref} />
					{pageInfo.wordgame&&<ListButton name="wordgame" title="單字遊戲" src={pageInfo.wordgame} />}
					{pageInfo.goldenmind&&<ListButton name="goldenmind" title="百萬金頭腦" src={pageInfo.goldenmind} />}
				</div>
				<VideoModal
					isActive={videoModalIsActive}
					bookId={bookId}
					videoInfo={videoModalData}
					onCloseVideoModal={closeVideoModal}
				/>
			</UiBody>
			<Popup isActive={popupIsActive} in={inProp}>
				<div className="head">
					<div className="title">{lessonHeader}</div>
					<div className="closeBtn" onClick={closePopup}>
						<Icon name="times" variant="headlines" />
					</div>
				</div>
				<div className="actions">
					<Button text="對話動畫" variant={foolProofCondition('dialogue')} onClick={videoInfoHandler('dialogue')} />
					<Button text="閱讀動畫" variant={foolProofCondition('read')} onClick={videoInfoHandler('read')} />
				</div>
			</Popup>
		</UiLessonPage>
	);
};

LessonPage.propTypes = {};
