import styled from 'styled-components';

export const UiKKSound = styled.div`
	display: block;
	font-size: 18px;
	font-family: Calibri, arial, 'helvetica neue', helvetica, 'microsoft jhenghei', 'PingFang TC', sans-serif;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.secondary};
	line-height: 1.5em;

	> span {
		color: ${({ theme }) => theme.colors.primary};
	}
`;
