import { Machine, assign } from 'xstate';
import { getBooks } from 'services/api/book';
import { convertArrayToMap } from 'utils/array';

export const States = {
	Idle: 'Idle',
	Resolved: 'Resolved',
	Rejected: 'Rejected',
};

export const ActionType = {};

export const BooksMachine = Machine({
	id: 'BooksMachine',
	initial: States.Idle,
	context: {
		books: {},
	},
	states: {
		[States.Idle]: {
			invoke: {
				id: 'fetchBooks',
				src: async (ctx) => {
					const { books } = await getBooks();
					return { books };
				},
				onDone: {
					target: States.Resolved,
					actions: assign({
						books: (ctx, event) => {
							const books = event.data.books;
							const nextBooks = books.map((book) => ({ ...book, lessons: convertArrayToMap(book.lessons, 'label') }));
							let booksMap = convertArrayToMap(nextBooks, 'bookId');
							return booksMap;
						},
					}),
				},
				onError: {
					target: States.Rejected,
				},
			},
		},
		[States.Resolved]: {},
		[States.Rejected]: {},
	},
});
