import styled from 'styled-components';
import { Box } from 'components';

export const UiTipsContent = styled.div`
	display: block;
`;

export const UiImageBox = styled(Box)`
	display: flex;
	justify-content: center;
`;

export const UiContentBox = styled(Box)`
	display: flex;
	justify-content: center;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
`;
