import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
// import PropTypes from 'prop-types';
import { useLessonState } from 'providers/LessonProvider';
import { UiStudyMethod, UiGrid, UiMethodButton } from './StudyMethod.style';

/**
 * 在這邊描述這個組件
 */

export const StudyMethod = () => {
	const history = useHistory();
	const { bookId, lesson } = useParams();
	const lessonState = useLessonState();

	const pathGenerator = (entity) =>
		generatePath('/:bookId/:lesson/:entity(vocabulary|example|dialogue|read)', {
			bookId,
			lesson,
			entity,
		});

	const foolProofCondition = (entity) => {
		if (!lessonState) return;
		let isExist = true;

		switch (entity) {
			case 'vocabulary':
			case 'example':
				if (lessonState.context['vocabulary'].contents.length === 0) {
					isExist = false;
				}
				break;
			case 'dialogue':
				if (!lessonState.context['dialogue'].audio.mp3 || !lessonState.context['dialogue'].audio.subtitles) {
					isExist = false;
				}
				break;
			case 'read':
				if (!lessonState.context['read'].audio.mp3 || !lessonState.context['read'].audio.subtitles) {
					isExist = false;
				}
				break;
			default:
				break;
		}
		return isExist;
	};

	const goPage = (entity) => () => {
		const isExist = foolProofCondition(entity);
		if (!isExist) return;

		history.push(pathGenerator(entity));
	};

	return (
		<UiStudyMethod>
			<UiGrid>
				<UiMethodButton
					className={foolProofCondition('vocabulary') ? '' : 'disable light'}
					variant="word"
					onClick={goPage('vocabulary')}>
					<div className="title">
						單字
						<br />
						學習
					</div>
				</UiMethodButton>
				<UiMethodButton
					className={foolProofCondition('dialogue') ? '' : 'disable dark'}
					variant="dialogue"
					onClick={goPage('dialogue')}>
					<div className="title">
						課文
						<br />
						對話篇
					</div>
				</UiMethodButton>
			</UiGrid>
			<UiGrid>
				<UiMethodButton
					className={foolProofCondition('example') ? '' : 'disable dark'}
					variant="sentences"
					onClick={goPage('example')}>
					<div className="title">
						單字
						<br />
						例句
					</div>
				</UiMethodButton>
				<UiMethodButton
					className={foolProofCondition('read') ? '' : 'disable light'}
					variant="read"
					onClick={goPage('read')}>
					<div className="title">
						課文
						<br />
						閱讀篇
					</div>
				</UiMethodButton>
			</UiGrid>
		</UiStudyMethod>
	);
};

StudyMethod.propTypes = {};
