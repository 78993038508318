import React from 'react';
import PropTypes from 'prop-types';
import { UiControlButton } from './ControlButton.style';

export const ControlButton = ({ className, children, active, onClick, ...props }) => (
	<UiControlButton active={active} onClick={onClick} {...props}>
		{children}
	</UiControlButton>
);

ControlButton.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	active: PropTypes.bool,
	onClick: PropTypes.func,
};
