import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { LessonHeader, Icon, Box, AudioButton, Popup, Button, CardPopup } from 'components';
import { useLessonState } from 'providers/LessonProvider';
import { useAudio } from 'utils/hooks/mediaElement';
import useSetState from 'utils/hooks/setState';
import { useBooksResource } from 'utils';
import {
	UiTextBookWrap,
	UiLessonTitle,
	UiLessonText,
	UiIconBtn,
	UiLessonContent,
	UiSentenceGroup,
	UiSentence,
	UiSentenceAction,
} from './TextBookWrap.style';

/**
 * 課文共用容器
 */

const BOOK_TYPE_MAP = {
	read: 'read',
	dialogue: 'dialogue',
};

export const TextBookWrap = ({ textBookType }) => {
	const [{ isAutoPlay }, setState] = useSetState({
		isAutoPlay: true,
	});
	const { lessonHeader, lessonTitle, getLessonAudioInfo } = useBooksResource();
	const lessonState = useLessonState();
	const vocabulary = lessonState.context.vocabulary.contents || [];
	const audioFileInfo = lessonState.context[BOOK_TYPE_MAP[textBookType]].audio || [];
	const lessonAudioInfo = getLessonAudioInfo(audioFileInfo);
	const [readSentence, setReadSentence] = useState([]);
	const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
	const [singleSentenceCurrentIndex, setSingleSentenceCurrentIndex] = useState(0);
	const lessonWords = vocabulary.map((item) => item.word);
	const { popupIsActive, inProp, closePopup, openPopup } = Popup.usePopup(false);
	const { cardPopupIsActive, inCardPopupProp, closeCardPopup, openCardPopup } = CardPopup.useCardPopup(false);
	const sentenceGroupRef = useRef({});
	const lessonContentRef = useRef();
	const lessonTextRef = useRef();
	const [isTitleOver, setIsTitleOver] = useState(false);

	const { element: audio, state, controls, activeCue, cues } = useAudio(
		<audio id="audio" src={lessonAudioInfo.mp3} playsInline autoPlay={isAutoPlay} crossOrigin="anonymous">
			<>
				<track default kind="subtitles" label="en" srcLang="en" src={lessonAudioInfo.subtitles} />
			</>
		</audio>,
	);

	const cuesFormate = (cues) => {
		return cues.map((cue) => {
			return {
				id: cue.id,
				text: cue.text,
				startTime: cue.startTime,
			};
		});
	};

	useEffect(() => {
		if (!activeCue.en?.id || !lessonContentRef || Object.keys(cues).length === 0) return;

		const currentIndex = cues.en.findIndex((cue) => {
			return cue.id === activeCue.en.id;
		});
		setCurrentTrackIndex(currentIndex);

		const targetElement = sentenceGroupRef.current[currentIndex];

		if (targetElement) {
			targetElement.scrollIntoView({ block: 'center' });
		}
	}, [activeCue.en?.id, lessonContentRef]);

	useEffect(() => {
		if (!cues.en) return;
		setReadSentence(cuesFormate(cues.en));
	}, [cues.en]);

	const onTitlePopupHandler = () => {
		openPopup();
	};

	const onAudioButtonClickHandler = () => {
		const isPlaying = state.isPlaying;
		isPlaying ? controls.pause() : controls.play();
	};

	const onSentenceGroupClickHandler = (startTime) => () => {
		controls.seek(startTime);
		controls.play();
	};

	const onSingleSentenceClickHandler = (event, activeIndex) => {
		event.stopPropagation();
		setState({
			activeIndex: activeIndex,
		});
		controls.pause();
		openCardPopup();
	};

	const swiperHandler = (index) => {
		setSingleSentenceCurrentIndex(index);
	};

	useEffect(() => {
		if (!cardPopupIsActive) return;
		const swiperCurrent = cues.en[singleSentenceCurrentIndex];
		controls.seek(swiperCurrent.startTime);
	}, [singleSentenceCurrentIndex]);

	useEffect(() => {
		if (lessonTextRef.current.offsetHeight > 32) {
			setIsTitleOver(true);
		}
	}, []);

	return (
		<UiTextBookWrap>
			{cardPopupIsActive && (
				<CardPopup
					audioType={BOOK_TYPE_MAP[textBookType]}
					currentIndex={currentTrackIndex}
					in={inCardPopupProp}
					onBackHandler={closeCardPopup}
					onSwiperHandler={swiperHandler}
				/>
			)}

			{audio}
			<LessonHeader title={lessonHeader} variant="inverse" />
			<UiLessonTitle>
				<UiLessonText ref={lessonTextRef} titleover={isTitleOver}>
					{lessonTitle}
				</UiLessonText>
				{isTitleOver && (
					<UiIconBtn onClick={onTitlePopupHandler}>
						<Icon name="expandCircle" variant={'inverse'} />
					</UiIconBtn>
				)}
			</UiLessonTitle>
			<UiLessonContent ref={lessonContentRef}>
				{readSentence.map((item, index) => (
					<UiSentenceGroup
						ref={(el) => (sentenceGroupRef.current[index] = el)}
						id={`track${index}`}
						current={index === currentTrackIndex}
						startTime={item.startTime}
						onClick={onSentenceGroupClickHandler(item.startTime)}
						key={index}>
						<UiSentence
							current={index === currentTrackIndex}
							dangerouslySetInnerHTML={{ __html: item.text }}></UiSentence>
						<UiSentenceAction current={index === currentTrackIndex}>
							<UiIconBtn onClick={(event) => onSingleSentenceClickHandler(event, index)}>
								<Icon name="arrowCircle" variant={'primary'} />
							</UiIconBtn>
						</UiSentenceAction>
					</UiSentenceGroup>
				))}
			</UiLessonContent>
			<Box display="flex" justifyContent="center" marginTop={35} marginBottom={24}>
				<AudioButton variant="primary" onClick={onAudioButtonClickHandler} />
			</Box>
			<Popup isActive={popupIsActive} in={inProp}>
				<div className="head">
					<div className="title">{lessonHeader}</div>
					<div className="closeBtn" onClick={closePopup}>
						<Icon name="times" variant="headlines" />
					</div>
				</div>
				<div className="content">{lessonTitle}</div>
				<div className="actions">
					<Button text="關閉視窗" variant="primary" onClick={closePopup} />
				</div>
			</Popup>
		</UiTextBookWrap>
	);
};

TextBookWrap.propTypes = {
	textBookType: PropTypes.string,
};
