import React from 'react';
import PropTypes from 'prop-types';
import { UiMainLayout, UiContainer } from './MainLayout.style';
import RenderRoutes from 'routes/RenderRoutes';

/**
 * MainLayout
 */

export const MainLayout = ({ routes = [] }) => {
	return (
		<UiMainLayout>
			<UiContainer maxWidth={['768px', '768px', '1024px']}>
				<RenderRoutes routes={routes} />
			</UiContainer>
		</UiMainLayout>
	);
};

MainLayout.propTypes = {
	routes: PropTypes.array,
};
