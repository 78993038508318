/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from 'react';

import useSetState from 'utils/hooks/setState';

const parseTimeRanges = (ranges) => {
	const result = [];

	for (let i = 0; i < ranges.length; i++) {
		result.push({
			start: ranges.start(i),
			end: ranges.end(i),
		});
	}

	return result;
};

const createHTMLMediaHook = (tag) => {
	const hook = (elOrProps) => {
		let element;
		let props;

		if (React.isValidElement(elOrProps)) {
			element = elOrProps;
			props = element.props;
		} else {
			props = elOrProps;
		}

		const [state, setState] = useSetState({
			buffered: [],
			time: 0,
			duration: 0,
			isPlaying: false,
			isLooping: false,
			isFullScreen: false,
			muted: false,
			volume: 0.7,
			playbackRate: 1.0,
			language: ['en'],
			activeCue: {},
			cues: [],
			isLoaded: false,
			isInit: false,
		});

		const ref = useRef(null);
		const cuesRef = useRef({});
		const activeCueRef = useRef({});

		const wrapEvent = (userEvent, proxyEvent) => {
			return (event) => {
				try {
					proxyEvent && proxyEvent(event);
				} finally {
					userEvent && userEvent(event);
				}
			};
		};

		const onPlay = () => setState({ isPlaying: true });
		const onPause = () => setState({ isPlaying: false });
		const onVolumeChange = () => {
			const el = ref.current;
			if (!el) {
				return;
			}
			setState({
				muted: el.muted,
				volume: el.volume,
			});
		};
		const onDurationChange = () => {
			const el = ref.current;
			if (!el) {
				return;
			}
			const { duration, buffered } = el;
			setState({
				duration,
				buffered: parseTimeRanges(buffered),
			});
		};
		const onTimeUpdate = () => {
			const el = ref.current;
			if (!el) {
				return;
			}
			setState({ time: el.currentTime });
		};
		const onProgress = () => {
			const el = ref.current;
			if (!el) {
				return;
			}
			setState({ buffered: parseTimeRanges(el.buffered) });
		};

		const formatAudio = (textTrack, cues, language) => {
			cuesRef.current[language] = Object.values(cues).map((cue) => {
				return {
					id: cue.id,
					startTime: cue.startTime,
					endTime: cue.endTime,
					text: cue.text,
					durationTime: cue.endTime - cue.startTime,
				};
			});

			const { activeCues } = textTrack;
			activeCueRef.current[language] =
				activeCues.length > 0
					? {
							id: activeCues[0].id,
							text: activeCues[0].text,
					  }
					: null;
		};

		if (element) {
			element = React.cloneElement(element, {
				controls: false,
				...props,
				ref,
				onPlay: wrapEvent(props.onPlay, onPlay),
				onPause: wrapEvent(props.onPause, onPause),
				onVolumeChange: wrapEvent(props.onVolumeChange, onVolumeChange),
				onDurationChange: wrapEvent(props.onDurationChange, onDurationChange),
				onTimeUpdate: wrapEvent(props.onTimeUpdate, onTimeUpdate),
				onProgress: wrapEvent(props.onProgress, onProgress),
			});
		} else {
			element = React.createElement(tag, {
				controls: false,
				...props,
				ref,
				onPlay: wrapEvent(props.onPlay, onPlay),
				onPause: wrapEvent(props.onPause, onPause),
				onVolumeChange: wrapEvent(props.onVolumeChange, onVolumeChange),
				onDurationChange: wrapEvent(props.onDurationChange, onDurationChange),
				onTimeUpdate: wrapEvent(props.onTimeUpdate, onTimeUpdate),
				onProgress: wrapEvent(props.onProgress, onProgress),
			});
		}

		let lockPlay = false;

		const controls = useRef({
			play: () => {
				const el = ref.current;
				if (!el) {
					return undefined;
				}

				if (!lockPlay) {
					const promise = el.play();
					const isPromise = typeof promise === 'object';

					if (isPromise) {
						lockPlay = true;
						const resetLock = () => {
							lockPlay = false;
						};
						promise.then(resetLock, resetLock);
					}

					return promise;
				}
				return undefined;
			},
			pause: () => {
				const el = ref.current;
				if (el && !lockPlay) {
					return el.pause();
				}
			},
			loop: (status = true) => {
				const el = ref.current;
				if (el) {
					el.loop = status;
					setState({ isLooping: status });
				}
			},
			seek: (time) => {
				const el = ref.current;
				if (!el || state.duration === undefined) {
					return;
				}
				if (`${time}`.indexOf(':') > -1) {
					time = Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
				}
				console.log('time', time);
				if (isNaN(time)) {
					return;
				}
				time = Math.min(el.duration, Math.max(0, time));
				el.currentTime = time;
			},
			volume: (volume) => {
				const el = ref.current;
				if (!el) {
					return;
				}
				volume = Math.min(1, Math.max(0, volume));
				el.volume = volume;
				setState({ volume });
			},
			mute: () => {
				const el = ref.current;
				if (!el) {
					return;
				}
				el.muted = true;
			},
			unmute: () => {
				const el = ref.current;
				if (!el) {
					return;
				}
				el.muted = false;
			},
			playbackRate: (rate) => {
				const el = ref.current;
				if (!el) {
					return;
				}
				const playbackRate = Number(rate);
				el.playbackRate = playbackRate;
				setState({ playbackRate });
			},
			fullscreen: () => {
				const el = ref.current.tagName === 'VIDEO' ? ref.current.parentNode : ref.current;
				if (!el) {
					return;
				}
				if (
					(document.fullScreenElement && document.fullScreenElement !== null) ||
					(!document.mozFullScreen && !document.webkitIsFullScreen)
				) {
					if (el.requestFullscreen) {
						el.requestFullscreen();
					} else if (el.msRequestFullscreen) {
						el.msRequestFullscreen();
					} else if (el.mozRequestFullScreen) {
						el.mozRequestFullScreen();
					} else if (el.webkitRequestFullscreen) {
						el.webkitRequestFullscreen();
					}
				} else {
					if (document.cancelFullScreen) {
						document.cancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					}
				}
			},
			setSubtitlesLanguage: (value = []) => {
				setState({ language: value.sort((a, b) => (a < b ? -1 : 1)) });
			},
		});

		useEffect(() => {
			setState({ time: 0 });
		}, [props.src, setState]);

		useEffect(() => {
			const el = ref.current;

			if (!el) {
				return;
			}

			setState({
				volume: el.volume,
				muted: el.muted,
				isPlaying: !el.paused,
				isLooping: el.loop,
				playbackRate: el.playbackRate,
				language: ['en'],
			});

			// Start media, if autoPlay requested.
			if (props.autoPlay && el.paused) {
				controls.current.play();
			}

			controls.current.setSubtitlesLanguage(state.language);
		}, [controls, props.autoPlay, props.src, setState, state.language]);

		useEffect(() => {
			const el = ref.current;
			if (!el) return;

			// only get first track
			const trackElement = el.querySelectorAll('track')[0];
			if (!trackElement) return;

			for (let i = 0; i < el.textTracks.length; i++) {
				const textTrack = el.textTracks[i];
				const { cues, language } = textTrack;
				if (!cues) return;
				if (!state.isInit) {
					formatAudio(textTrack, cues, language);
					setState({
						...state,
						isInit: true,
					});
				}
				textTrack.oncuechange = () => formatAudio(textTrack, cues, language);
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.src, state.isLoaded]);

		useEffect(() => {
			const el = ref.current;
			if (!el) {
				return;
			}

			const addtrackHandler = () => {
				const textTracks = Object.values(el.textTracks);
				textTracks.forEach((textTrack) => {
					if (state.language.includes(textTrack.language)) {
						textTrack.mode = 'showing';
					} else {
						textTrack.mode = 'hidden';
					}
				});
			};
			el.textTracks.addEventListener('addtrack', addtrackHandler);
			return () => {
				el.textTracks.removeEventListener('addtrack', addtrackHandler);
			};
		}, [state.language]);

		useEffect(() => {
			const el = ref.current;
			if (!el) return;
			const onload = (isLoaded) => () => {
				setState({ isLoaded });
			};
			el.addEventListener('loadedmetadata', onload(true));
			return () => {
				el.removeEventListener('loadedmetadata', onload(false));
			};
		}, [setState]);

		// return [element, state, controls.current, ref, ];
		return {
			element,
			state,
			controls: controls.current,
			elementRef: ref,
			cues: cuesRef.current,
			activeCue: activeCueRef.current,
			currentTime: state.time,
		};
	};

	return hook;
};

export const useVideo = createHTMLMediaHook('video');
export const useAudio = createHTMLMediaHook('audio');

export default createHTMLMediaHook;
