import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { UiLessonHeader } from './LessonHeader.style';

/**
 * 在這邊描述這個組件
 */

export const LessonHeader = ({ title, variant }) => {
	const backHandler = () => {
		history.goBack();
	};
	const history = useHistory();
	return (
		<UiLessonHeader variant={variant}>
			<div className="backAction" onClick={backHandler}>
				<Icon name="chevronLeft" variant={variant} />
			</div>
			<div className="title">{title}</div>
		</UiLessonHeader>
	);
};

LessonHeader.propTypes = {
	title: PropTypes.string,
	variant: PropTypes.oneOf(['primary', 'inverse']),
};
