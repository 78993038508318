import styled from 'styled-components';
import { Box } from 'components';
import theme from 'providers/ThemeProvider/theme';

export const UiListButton = styled.div`
	display: flex;
	align-items: center;
	padding: 20px;
	font-size: 0;
	text-decoration: none;
	background-color: #f2f4f8;
	border-radius: 10px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

	&:active {
		background-color: #eeeef1;
	}

	& + & {
		margin-top: 16px;
	}

	&.disable {
		.title {
			color: #d5d7de;
		}
	}

	> .icon {
		margin-right: 28px;
	}

	> .title {
		width: 100%;
		font-size: 18px;
		line-height: 24px;
		color: ${theme.colors.paragraphs};
	}
`;
