import styled from 'styled-components';

export const UiVocabularyPage = styled.div`
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.primary};

	> .pageTitle {
		padding: 15px 0;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
		color: ${({ theme }) => theme.colors.white};
		line-height: 1.5em;
	}

	.swiper-container {
		flex: 1;
		width: 100%;
	}

	.swiper-slide {
		width: 80%;
		height: 100%;
	}
`;
