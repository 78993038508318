import React from 'react';
import PropTypes from 'prop-types';
import { Box, KKSound } from 'components';
import { UiVocabularyCard } from './VocabularyCard.style';

/**
 * 單字學習 卡牌
 */

export const VocabularyCard = ({ className, word, KK, partOfSpeech, translation }) => {
	return (
		<UiVocabularyCard className={className}>
			<div className="word">{word}</div>
			<KKSound className="KK">{KK}</KKSound>
			<Box display="flex" alignItem="center" marginTop={20}>
				<div className="partOfSpeech">{partOfSpeech}</div>
				{translation && <div className="translation">{translation}</div>}
			</Box>
		</UiVocabularyCard>
	);
};

VocabularyCard.propTypes = {
	word: PropTypes.string,
	KK: PropTypes.string,
	partOfSpeech: PropTypes.string,
	className: PropTypes.string,
	translation: PropTypes.string,
};
