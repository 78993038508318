import styled from 'styled-components';
import { variant } from 'styled-system';

export const UiLessonHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 0;
	font-size: 0;

	> .title {
		flex: 0 0 calc(100% - 32px * 2);
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		line-height: 27px;

		${variant({
			variants: {
				primary: {
					color: 'paragraphs',
				},
				inverse: {
					color: 'inverse',
				},
			},
		})}
	}
`;
